import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:"/admin",
    component: HomeView,
    children:[
      {
        path: '/admin',
        name: 'admin',
        component: () => import( '../views/admin/AdminView.vue')
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import( '../views/admin/systemAdministration/UserView.vue')
      },
      {
        path: '/system/employeeView',
        name: 'user',
        component: () => import( '../views/admin/systemAdministration/EmployeeView.vue')
      },
      {
        path: '/system/userAddNewView',
        name: 'user',
        component: () => import( '../views/admin/systemAdministration/UserAddNewView.vue')
      },
      {
        path: '/system/employeeAddNewView',
        name: 'user',
        component: () => import( '../views/admin/systemAdministration/EmployeeAddNewView.vue')
      },
      {
        path: '/signInView',
        name: 'user',
        component: () => import( '../views/admin/SignInView')
      },
      {
        path: '/ordersView',
        name: 'user',
        component: () => import( '../views/admin/OrdersView')
      },
      {
        path: '/announcementManagementView',
        name: 'user',
        component: () => import( '../views/admin/AnnouncementManagementView')
      },
      {
        path: '/announcementListView',
        name: 'user',
        component: () => import( '../views/admin/AnnouncementListView.vue')
      },
      {
        path: '/evaluationManagementView',
        name: 'user',
        component: () => import( '../views/admin/EvaluationManagementView')
      },
      {
        path: '/orderRefundsView',
        name: 'user',
        component: () => import( '../views/admin/OrderRefundsView')
      },
      {
        path: '/packageManagement',
        name: 'user',
        component: () => import( '../views/admin/PackageManagement')
      },
      {
        path: '/carouselManagementView',
        name: 'user',
        component: () => import( '../views/admin/CarouselManagementView')
      },
      {
        path: '/CarouselAddNewManagementView',
        name: 'user',
        component: () => import( '../views/admin/CarouselAddNewManagementView')
      },
      {
        path: '/yiyuan',
        name: 'user',
        component: () => import( '../views/admin/yiyuan')
      },
      {
        path: '/xiugaiyiyuan',
        name: 'user',
        component: () => import( '../views/admin/xiugaiyiyuan')
      },
      {
        path: '/taocan',
        name: 'user',
        component: () => import( '../views/admin/taocan')
      },
      {
        path: '/HospitalManagement',
        name: 'HospitalManagement',
        component: () => import( '../views/admin/HospitalManagement')
      }
    ]
  },{
    path: '/reg',
    name: 'reg',
    component: () => import( '../views/RegUser.vue')

  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
