const BaseUrl = {
 /*   PASSPORT: 'http://localhost:29081',
    MALL_ADMIN: 'http://localhost:29182',
    Staff:'http://localhost:29980',
    ADMINVIEW:'http://localhost:23300',
    ANNOUNCEMENT:'http://localhost:19180',
    BANNER:'http://localhost:19280',
    COMMENT:'http://localhost:19980',
    HOSPITAL:'http://localhost:29999',
    ORDER:'http://localhost:19680',
    GOODS:'http://localhost:29282',
    SIGNIN:'http://localhost:19880',
    ADDRESS:'http://localhost:29222'*/

    PASSPORT: 'https://passport.iqiv.cn',
    MALL_ADMIN: 'http://localhost:29182',
    Staff:'https://staff.iqiv.cn',
    ADMINVIEW:'https://visualization.iqiv.cn',
    ANNOUNCEMENT:'https://announcement.iqiv.cn',
    BANNER:'https://banner.iqiv.cn',
    COMMENT:'https://evaluation.iqiv.cn',
    HOSPITAL:'https://hospital.iqiv.cn',
    ORDER:'https://order.iqiv.cn',
    GOODS:'https://goods.iqiv.cn',
    SIGNIN:'https://singin.iqiv.cn',
    ADDRESS:'https://basic.iqiv.cn'
};

export default BaseUrl;
